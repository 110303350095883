import * as React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout/layout"
import {
  MouseParallaxChild,
  MouseParallaxContainer
} from "react-parallax-mouse"
import Settings from "../../settings.js"
import md5 from "js-md5"
import {
  TwitterShareButton,
  TelegramShareButton,
  FacebookShareButton
} from "react-share";

const SharePage = () => {
  // Get quiz data
  let data = Settings.BillAckman;
  let totalBalance, betterThan, link, twitterShared, telegramShared, facebookShared, userId, bitcoinRate;
  if (typeof window !== 'undefined') {
    if (
      localStorage.getItem('quiz-current-balance') !== 'null' &&
      localStorage.getItem('quiz-question-1-data') !== 'null' &&
      localStorage.getItem('quiz-question-2-data') !== 'null' &&
      localStorage.getItem('quiz-question-3-data') !== 'null' &&
      localStorage.getItem('quiz-question-4-data') !== 'null' &&
      localStorage.getItem('quiz-question-5-data') !== 'null'
    ) {
      totalBalance = parseFloat(localStorage.getItem('quiz-current-balance'));

      bitcoinRate = (parseFloat(localStorage.getItem('quiz-bitcoin-rate')) > 0) ? parseFloat(localStorage.getItem('quiz-bitcoin-rate')) : 20000;

      betterThan = Math.floor(totalBalance * Settings.betterThanFactor);

      userId = Math.floor(parseFloat(localStorage.getItem('quiz-user-id')))

      const summaryData = [
        JSON.parse(localStorage.getItem('quiz-question-1-data')),
        JSON.parse(localStorage.getItem('quiz-question-2-data')),
        JSON.parse(localStorage.getItem('quiz-question-3-data')),
        JSON.parse(localStorage.getItem('quiz-question-4-data')),
        JSON.parse(localStorage.getItem('quiz-question-5-data'))
      ];

      let correctAnswers = 0;
      let leverageSumm = 0;
      let sellOperations = 0;
      for (let i in summaryData) {
        // Number of correct answers
        if (summaryData[i].correctAnswer === true) correctAnswers++;

        // Numbers of Buy & Sell operations
        if (summaryData[i].type === 'Sell') sellOperations++;

        // Leverages arithmetic average
        leverageSumm += parseFloat(summaryData[i].leverage);
      }
      let averageLeverage = leverageSumm / summaryData.length;

      // console.warn(
      //   'correctAnswers: ' + correctAnswers + ' | ' +
      //   'average leverage: ' + averageLeverage + ' | ' +
      //   'sell operations: ' + sellOperations
      // );

      if (
        correctAnswers === 3 &&
        averageLeverage > 70 &&
        sellOperations !== 2
      ) data = Settings.RayDalio;

      else if (
        sellOperations === 2
      ) data = Settings.DavidEinhorn;

      else if (
        correctAnswers === 3 &&
        averageLeverage <= 20
      ) data = Settings.WarrenBuffet;

      else if (
        correctAnswers === 2
      ) data = Settings.EdwardThorp;

      else if (
        correctAnswers === 3 &&
        averageLeverage >= 40 &&
        averageLeverage <= 70
      ) data = Settings.JohnPaulson;

      else data = Settings.BillAckman;

      // Create sharing link
      link = window.location.origin + '/myresults?data=' + md5(data.personName.replace(' ', '')) + '-' + correctAnswers + '-' + averageLeverage + '-' + sellOperations + '-' + totalBalance + '-' + userId;

      // Already shared
      twitterShared = (localStorage.getItem('quiz-twitter-shared') === 'true') ? '-shared' : '';
      telegramShared = (localStorage.getItem('quiz-telegram-shared') === 'true') ? '-shared' : '';
      facebookShared = (localStorage.getItem('quiz-facebook-shared') === 'true') ? '-shared' : '';

      return (
        <Layout>
          <Helmet>
            <body className="-blue results" />
          </Helmet>
          <MouseParallaxContainer
            enabled={Settings.parallaxOn}
            className="screen results"
          >
    
            <div className="results__header">
              <p className="results__header__paragraph">
                <span
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="0.5"
                  data-animation="translateY"
                >Your final balance&nbsp;</span>
                <span
                  className="results__header__paragraph__accent"
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="1"
                  data-count-type="count"
                  data-count-delay="1.5"
                  data-count-finish={totalBalance}
                ><i>$</i><span>{totalBalance.toLocaleString('en')}</span></span>
                <span
                  className="results__header__paragraph__accent"
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="3"
                  data-animation="scaleDown"
                >&nbsp;({((totalBalance / bitcoinRate).toFixed(2)).toLocaleString('en')} BTC)</span>
              </p>
              <p className="results__header__paragraph">
                <span
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="4"
                  data-animation="scaleDown"
                >You did better than&nbsp;</span>
                <span
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="4"
                  data-count-type="count"
                  data-count-delay="4.5"
                  data-count-step="1"
                  data-count-finish={betterThan}
                ><span>{betterThan.toLocaleString('en')}</span></span>
                <span
                  data-type="animated-show"
                  data-duration="0.5"
                  data-delay="5.5"
                  data-animation="scaleDown"
                >&nbsp;other quiz participants!</span>
              </p>
            </div>
    
            <div className="results__main -share">
              <div className="share">
                <div className={"results__main__image -left " + data.imageClass}/>
                <div className={"results__main__image -right " + data.imageClass}/>
                <div data-to="/results" className="share__close"/>
                <h6 className="share__title">Share your result:</h6>
                <div className="share__radiogroup">
                  <TwitterShareButton
                    className={"share__radiogroup__item -twitter " + twitterShared}
                    url={link}
                  ></TwitterShareButton>
                  <TelegramShareButton
                    className={"share__radiogroup__item -telegram " + telegramShared}
                    url={link}
                  ></TelegramShareButton>
                  <FacebookShareButton
                    className={"share__radiogroup__item -facebook " + facebookShared}
                    url={link}
                  ></FacebookShareButton>
                </div>
                <div className="form -share">
                  <span className="form__label">
                    Or copy link
                  </span>
                  <div className="form__controls">
                    <input type="text" className="form__controls__input" value={link} disabled/>
                    <button className="form__controls__button" id="copy-to-clipboard">
                      <span className="form__controls__button__text">Copy</span>
                      <span className="form__controls__button__success">Copied</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
    
            <div
              className="results__description"
            >
              <span className="desktop">
                Margex is a reliable and innovative way to trade crypto with up to 100x leverage.<br />
                Margex helps to leverage your trading skills and maximize profits.
              </span>
              <span className="mobile">
                Margex is a reliable and innovative way to trade crypto with up to 100x leverage. Margex helps to leverage your trading skills and maximize profits.
              </span>
            </div>
    
            <div
              className="results__social"
            >
              <a
                href={Settings.twitterURL}
                rel="noopener noreferrer"
                target="_blank"
                className="results__social__item -twitter"
              >Twitter</a>
              <a
                href={Settings.telegramURL}
                rel="noopener noreferrer"
                target="_blank"
                className="results__social__item -telegram"
              >Telegram</a>
              <a
                href={Settings.facebookURL}
                rel="noopener noreferrer"
                target="_blank"
                className="results__social__item -facebook"
              >Facebook</a>
            </div>
    
            <MouseParallaxChild
              className="screen__person"
              factorX={0.04}
              factorY={0.04}
            >
              <div
                className={data.imageClass}
                data-type="animated-show"
                data-duration="0.1"
                data-delay="0.05"
              />
            </MouseParallaxChild>
            <MouseParallaxChild
              className="screen__dots"
              factorX={0.12}
              factorY={0.12}
            >
              <div
                className="-left"
                data-type="animated-show"
                data-duration="0.1"
                data-delay="0.15"
              />
            </MouseParallaxChild>
            <MouseParallaxChild
              className="screen__dots"
              factorX={0.4}
              factorY={0.4}
            >
              <div
                className="-right"
                data-type="animated-show"
                data-duration="0.1"
                data-delay="0.55"
              />
            </MouseParallaxChild>
            <MouseParallaxChild
              className="screen__chart"
              factorX={0.16}
              factorY={0.16}
            >
              <div
                className="-green -down"
                data-type="animated-show"
                data-animation="scaleDown"
                data-duration="0.1"
                data-delay="0.2"
              />
            </MouseParallaxChild>
            <MouseParallaxChild
              className="screen__chart"
              factorX={0.48}
              factorY={0.48}
            >
              <div
                className="-green -up"
                data-type="animated-show"
                data-animation="scaleUp"
                data-duration="0.1"
                data-delay="0.6"
              />
            </MouseParallaxChild>
            <MouseParallaxChild
              className="screen__bitcoin"
              factorX={0.24}
              factorY={0.24}
            >
              <div
                className="-bottom"
                data-type="animated-show"
                data-animation="scaleUp"
                data-duration="0.1"
                data-delay="0.3"
              />
            </MouseParallaxChild>
          </MouseParallaxContainer>
        </Layout>
      )
    }
  }
}

export default SharePage
